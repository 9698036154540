type MenuItem = {
  label: string
  uri: string
}

const menuItems: MenuItem[] = [
  {
    label: "The Film",
    uri: "/",
  },
  {
    label: "Resources",
    uri: "/resources",
  },
  { label: "About", uri: "/about" },
]

export default menuItems
