import React, { useRef, useState, useEffect } from "react"
import { Toolbar, ToolbarItem, useToolbarState } from "reakit/Toolbar"
import { Button } from "reakit/Button"
import {
  MenuArrow,
  MenuButton,
  Menu,
  useMenuState,
  MenuItem,
} from "reakit/Menu"
import StaticDrawer from "./drawer"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import theme from "./theme"
import ieomdLogo from "./assets/ieomdLogoBlack.svg"
import { useLocation } from "@reach/router"
import menuItems from "./menu-items"

interface Props {
  menu: any
  hidden?: boolean
  alternate?: boolean
}

export function localizeUrl(url: string) {
  return /^(https?:)?\/\//.test(url)
    ? new URL(url).pathname.replace(/\/$/, "")
    : url
}

function normalizeUrls(items: any[]) {
  for (const item of items) {
    item.uri = localizeUrl(item.uri)
    if (item.childItems) {
      normalizeUrls(item.childItems.nodes)
    }
  }
}

const MenuBar = (props: Props) => {
  const [hidden, hide] = useState(props.hidden)
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const timer = useRef(null)

  // Remove any trailing slash from the current path.
  const currentPath = useLocation().pathname.replace(/\/$/, "")

  // Hide the drawer when we navigate to a different page.
  useEffect(() => {
    setDrawerOpen(false)
  }, [currentPath])

  const timedShow = (e) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    hide(false)
  }
  const timedHide = (e?: any) => {
    if (props.hidden) {
      timer.current = setTimeout(() => hide(true), 200)
    }
  }
  useEffect(() => {
    if (props.hidden) {
      timedHide()
    }
  }, [props.hidden])

  const MenuComp = props.alternate ? MenuTypeB : MenuTypeA

  const menu = { items: menuItems }
  return (
    <>
      <StaticDrawer isOpen={isDrawerOpen} setOpen={setDrawerOpen} menu={menu} />
      <MenuComp
        hide={timedHide}
        show={timedShow}
        toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
        hidden={props.hidden && hidden}
        menu={menu}
        currentPath={currentPath}
      />
    </>
  )
}
export default MenuBar

const MenuTypeA = (props: {
  hide: () => void
  show: () => void
  toggleDrawer: () => void
  hidden: boolean
  menu: any
  currentPath: string
}) => {
  const toolbar = useToolbarState()
  return (
    <FixedToolbar
      {...toolbar}
      aria-label="Navigation Toolbar"
      onMouseEnter={props.show}
      onMouseLeave={props.hide}
    >
      <Logo {...toolbar} />
      <NavMenu
        style={{
          transform: props.hidden ? "translateY(-100px)" : "none",
        }}
      >
        {props.menu &&
          props.menu.items.map((item: any) => (
            <Item
              key={item.uri}
              item={item}
              isActive={item.uri == props.currentPath}
              {...toolbar}
            />
          ))}
        <Hamburger {...toolbar} onClick={props.toggleDrawer}>
          Menu
        </Hamburger>
      </NavMenu>
    </FixedToolbar>
  )
}

const MenuTypeB = (props: {
  hide: () => void
  show: () => void
  toggleDrawer: () => void
  hidden: boolean
  menu: any
  currentPath: string
}) => {
  const toolbar = useToolbarState()
  return (
    <FixedToolbar
      {...toolbar}
      aria-label="Navigation Toolbar"
      onMouseEnter={props.show}
      onMouseLeave={props.hide}
    >
      <Logo {...toolbar} />
      <NavMenu
        style={{
          transform: props.hidden ? "translateY(-100px)" : "none",
          background: "none",
          borderBottom: "none",
          "button:hover": {
            color: theme.colors.highlight,
            backgroundColor: "#3a3a3a",
          },
        }}
      >
        {props.menu &&
          props.menu.items.map((item: any) => (
            <Item
              key={item.uri}
              isActive={item.uri == props.currentPath}
              item={item}
              {...toolbar}
            />
          ))}
        <Hamburger {...toolbar} onClick={props.toggleDrawer}>
          Menu
        </Hamburger>
      </NavMenu>
    </FixedToolbar>
  )
}

const FixedToolbar = styled(Toolbar)({
  fontFamily: theme.fonts.header,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 9999,
  transform: "none",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "flex-end",
  background: "#00000000",
  borderBottom: "0px solid lightgray",
  fontWeight: "bold",
  textTransform: "uppercase",
})

const NavMenu = styled.nav({
  transition: "transform 300ms",
  width: "100%",
  borderBottom: "2px solid",
  borderBottomColor: theme.colors.highlight,
  background: "#1d1d1dcf",
  padding: "20px 48px",
  display: "flex",
  justifyContent: "flex-end",
  [theme.mq.mobile]: {
    padding: "15px 5px",
  },
})

const Hamburger = styled(ToolbarItem)({
  marginLeft: 10,
  marginRight: 10,
  color: "#909090",
  display: "none",
  [theme.mq.mobile]: {
    display: "initial",
  },
})

const SubMenu = React.forwardRef(
  (
    p: { parent: any; menuComponent?: any } & Partial<
      React.ComponentProps<typeof MenuButton>
    >,
    ref
  ) => {
    const { parent, menuComponent, ...rest } = p
    const menu = useMenuState()
    const MenuComp = menuComponent || Menu
    return (
      <>
        <MenuButton ref={ref} {...menu} {...rest}>
          {parent.label}
        </MenuButton>
        <MenuComp {...menu} aria-label={parent.label}>
          {parent.childItems.nodes.map((item: any) =>
            item.childItems?.nodes ? (
              <MenuItem
                {...menu}
                key={item.label}
                as={SubMenu}
                menuComponent={SecondLevel}
                parent={item}
              />
            ) : (
              <MenuItem {...menu} key={item.label} as={Link} to={item.uri}>
                {item.label}
              </MenuItem>
            )
          )}
        </MenuComp>
      </>
    )
  }
)

const FirstLevel = styled(Menu)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: theme.colors.dark,
  border: "1px solid #909090",
  width: 100,
  "&:hover": {
    color: theme.colors.highlight,
  },
  button: {
    color: "#909090",
    padding: "0 0 0 5px",
    border: "none",
    width: "100%",
    textAlign: "left",
  },
  "button:hover": {
    color: theme.colors.highlight,
    backgroundColor: "#3a3a3a",
  },
  a: {
    color: "#909090",
    padding: "0 0 0 5px",
    border: "none",
    width: "100%",
    textAlign: "left",
  },
  "a:hover": {
    color: theme.colors.highlight,
    backgroundColor: "#3a3a3a",
  },
  [theme.mq.mobile]: {},
})
const SecondLevel = styled(Menu)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: theme.colors.dark,
  border: "1px solid #909090",
  width: 200,
  "&:hover": {
    color: theme.colors.highlight,
  },
  a: {
    color: "#909090",
    padding: "0 0 0 5px",
    border: "none",
    width: "100%",
    textAlign: "right",
    padding: "5px 8px",
  },
  "a:hover": {
    color: theme.colors.highlight,
    backgroundColor: "#3a3a3a",
  },
  [theme.mq.mobile]: {},
})

const Item = styled(({ item, isActive, ...toolbar }) => {
  if (item.childItems) {
    return (
      <ToolbarItem
        {...toolbar}
        as={SubMenu}
        menuComponent={FirstLevel}
        parent={item}
      />
    )
  } else {
    return (
      <ToolbarItem {...toolbar} as={Link} to={item.uri}>
        {item.label}
      </ToolbarItem>
    )
  }
})(
  {
    fontSize: "1rem",
    marginLeft: 10,
    marginRight: 10,
    color: "#909090",
    letterSpacing: "0.03em",
    margin: "auto 10px",
    "&:hover": {
      color: theme.colors.highlight,
    },
    [theme.mq.mobile]: {
      display: "none",
    },
  },
  (p: { isActive?: boolean }) => ({
    color: p.isActive ? theme.colors.highlight : undefined,
  })
)

const LogoImg = styled.img({
  width: "200px",
  [theme.mq.mobile]: {
    width: "150px",
    margin: 0,
  },
})

const Logo = styled((toolbar) => (
  <ToolbarItem {...toolbar} as={Link} to="/">
    <LogoImg src={ieomdLogo} />
  </ToolbarItem>
))({
  fontSize: "1.5rem",
  color: theme.colors.highlight,
  filter:
    "invert(100%) sepia(0%) saturate(7500%) hue-rotate(353deg) brightness(112%) contrast(104%)",
  position: "absolute",
  zIndex: 1,
  top: 16,
  left: 20,
  width: "200px",
  "&:hover": {
    filter:
      "invert(93%) sepia(5%) saturate(2399%) hue-rotate(55deg) brightness(94%) contrast(110%)",
  },
})
