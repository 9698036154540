import React, { useRef, useState, useEffect } from "react"
import { withProps } from "recompose"
import { Toolbar, ToolbarItem, useToolbarState } from "reakit/Toolbar"
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from "reakit/Disclosure"
import { Button } from "reakit/Button"
import Drawer from "rc-drawer"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import theme from "./theme"
import { localizeUrl } from "./menu"

export default (p: {
  isOpen: boolean
  setOpen: (b: boolean) => void
  menu: any
}) => (
  <StaticDrawer
    open={p.isOpen}
    placement="left"
    width="300px"
    maskClosable={true}
    onClose={() => p.setOpen(false)}
    level={null}
    handler={null}
  >
    {p.menu ? (
      <MenuNav>
        <MenuItems items={p.menu.items} />
      </MenuNav>
    ) : null}
  </StaticDrawer>
)

export const StaticDrawer = styled(Drawer)({
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: "9999",
  display: "none",
  [theme.mq.mobile]: {
    display: "initial",
  },

  "&, & > *": {
    transition: "transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)",
  },

  ".drawer-mask": {
    opacity: 0,
    position: "fixed",
    top: 0,
    left: 0,
    background: "black",
  },

  "&.drawer-open": {
    width: "100%",
  },

  "&.drawer-open .drawer-mask": {
    opacity: "0.3",
    width: "100%",
    height: "100%",
  },
  "& .drawer-content-wrapper": {
    position: "absolute",
    height: "100%",
    backgroundColor: theme.colors.highlight,
    left: 0,
  },
  ".drawer-content": {
    position: "relative",
    overflow: "scroll",
  },

  ".drawer-handle": {
    display: "none",
    position: "absolute",
    top: 0,
    right: "-40px",
    height: "40px",
    width: "40px",
    "&-icon::before": {
      content: `"X"`,
    },
  },
})

const MenuItems = (props: { items: any[] }) => (
  <>
    {props.items.map((item: any) =>
      item.child_items ? (
        <NestedDrawerItem item={item} />
      ) : (
        <DrawerItem key={item.title} as={Link} to={localizeUrl(item.url)}>
          {item.title}
        </DrawerItem>
      )
    )}
  </>
)

const MenuNav = styled.nav({
  height: "100vh",
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  paddingLeft: 30,
  paddingTop: 10,
  a: {
    color: theme.colors.dark,
  },
})

const SubMenu = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  paddingLeft: 30,
  "button, a": {
    fontSize: "1.5rem",
  },
})

export const DrawerItem = styled(Button)({
  marginTop: 20,
  fontSize: "2rem",
  color: "#717171",
  textAlign: "left",
})

const NestedDrawerItem = (p: { item: any }) => {
  const disclosure = useDisclosureState()
  return (
    <>
      <Disclosure {...disclosure} as={DrawerItem}>
        {p.item.title}
      </Disclosure>
      <DisclosureContent {...disclosure} as={SubMenu}>
        <MenuItems items={p.item.child_items} />
      </DisclosureContent>
    </>
  )
}
