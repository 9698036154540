import React, { useState, createContext, useContext } from "react"
import { useStaticQuery } from "gatsby"
import { AnimatePresence } from "framer-motion"
import { Global } from "@emotion/react"
import { globalStyles } from "../theme"
import Menu from "../menu"

// Globally used fonts!
import "@fontsource/arimo"
import "../assets/DIN/demo.css"
import "../assets/Druk/fonts.css"

const MenuContext = createContext({
  show: (b: boolean) => {},
  setAlternate: (b: boolean) => {},
})
export const useMenu = () => useContext(MenuContext)

const AppContainer = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true)
  const [menuAlt, setMenuAlt] = useState(false)
  return (
    <>
      <Menu hidden={!showMenu} alternate={menuAlt} />
      <MenuContext.Provider
        value={{ show: setShowMenu, setAlternate: setMenuAlt }}
      >
        <AnimatePresence initial={true} exitBeforeEnter={false}>
          {children}
        </AnimatePresence>
      </MenuContext.Provider>
      <Global styles={globalStyles} />
    </>
  )
}
export default AppContainer
